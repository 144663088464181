import React from 'react'
import PropTypes from 'prop-types'
import App from 'App'
import SEO from 'components/SEO'
import Container from 'components/Container'
import Footer from 'components/Footer'
import { Box, Flex } from 'components/Layout'
import { H1, Text } from 'components/Typography'
import VideoText from './VideoText'

const ExplainerVideosPageTemplate = ({ pageContext: { title, description, pagePath, videos } }) => {
  return (
    <App>
      <SEO title={title} description={description} path={pagePath} />
      <Container maxWidth="1080px">
        <Flex mt="xl" flexDirection="column" alignItems="center" textAlign="center">
          <H1 textAlign="center">{title}</H1>
          <Box maxWidth="768px" px="m">
            <Text fontSize="l" mt="m" color="secondaryText" textAlign="center" opacity={0.8}>
              {description}
            </Text>
          </Box>
        </Flex>
      </Container>
      <Container maxWidth="1080px">
        {videos.map((content, index) => (
          <Box id={content.id} key={content.title} py={{ mobile: 'xl', tablet: 'xxl' }}>
            <VideoText isReversed={index % 2 !== 0} isSecondBubbleVisible={index % 2 !== 1} {...content} />
          </Box>
        ))}
      </Container>
      <Box mt="xxl">
        <Footer />
      </Box>
    </App>
  )
}

ExplainerVideosPageTemplate.propTypes = {
  pageContext: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    pagePath: PropTypes.string.isRequired,
    videos: PropTypes.array,
  }),
}

export default ExplainerVideosPageTemplate
