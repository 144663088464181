import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { position } from 'styled-system'
import { Flex, Box } from 'components/Layout'
import { H2, Text } from 'components/Typography'
import LocalImage from 'components/LocalImage'
import shape1 from '../../static/images/shape1.png'
import shape2 from '../../static/images/shape2.png'

const BgImage = styled(LocalImage)`
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth};
  height: auto;
  z-index: -1;
  position: absolute;
  ${position};
`

const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const ImageText = ({ title, description, link, slug, isReversed, isSecondBubbleVisible }) => (
  <Flex
    flexDirection={['column', 'row']}
    justifyContent={{ mobile: 'center', desktop: 'space-between' }}
    alignItems="center"
    flexWrap="wrap"
  >
    <Flex order={[1, isReversed ? 2 : 1]} flexDirection="column" maxWidth="430px">
      <H2>{title}</H2>
      <Text
        mt="m"
        color="ternaryText"
        fontSize="xl"
        opacity={0.9}
        lineHeight="xl"
        dangerouslySetInnerHTML={{
          __html: `${description} <a color="primary" href="/explainer-video/${slug}/" target="_blank">View Video</a>`,
        }}
      />
    </Flex>
    <Flex
      width="100%"
      maxWidth="520px"
      order={[2, isReversed ? 1 : 2]}
      mt={['l', '0']}
      justifyContent="center"
      position="relative"
    >
      <Box maxWidth="520px" height="0" pb="56.25%">
        <StyledIframe
          title=""
          src={link}
          frameBorder="0"
          allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Box>
      <BgImage src={shape1} left={-60} bottom={-60} maxWidth="220px" />
      {isSecondBubbleVisible && (
        <BgImage
          src={shape2}
          top={-60}
          right={[0, -60]}
          maxWidth="300px"
          display={{ mobile: 'none', tablet: 'block' }}
        />
      )}
    </Flex>
  </Flex>
)

ImageText.defaultProps = {
  isReversed: false,
  isSecondBubbleVisible: false,
}

ImageText.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  isReversed: PropTypes.bool,
  isSecondBubbleVisible: PropTypes.bool,
}

export default ImageText
